<ion-app>
  <ion-menu contentId="main-content"  type="overlay" side="end">
    <ion-content>
      <ion-list id="inbox-list">
        <a routerLink="/inicio"><img src="assets/img/logo_integra.webp" class="logo"></a>

        <ion-menu-toggle auto-hide="false">
          <ion-item routerDirection="root" routerLink="/inicio" lines="none" detail="false" routerLinkActive="selected">
            <!-- <ion-icon aria-hidden="true" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon> -->
            <ion-label>Início</ion-label>
          </ion-item>
        </ion-menu-toggle>
          <ion-accordion-group>
            <ion-accordion value="first">
              <ion-item slot="header" color="light">
                <ion-label>Acesso rápido</ion-label>
              </ion-item>
              <div class="ion-padding" slot="content">
                @for (item of quickAccess; track $index) {
                  <ion-item lines="none" detail="false" (click)="navigate(item.url)">
                    <ion-label>{{ item.title }}</ion-label>
                  </ion-item>
                }
              </div>
            </ion-accordion>
          </ion-accordion-group>
        <ion-menu-toggle auto-hide="false">
          <ion-item routerDirection="root" routerLink="/ajuda" lines="none" detail="false" routerLinkActive="selected">
            <ion-label>Ajuda</ion-label>
          </ion-item>
          <ion-item *ngIf="logged!='true'" lines="none" detail="false" (click)="login()">
            <ion-label>Entrar</ion-label>
          </ion-item>
          <ion-item *ngIf="logged!='false'" lines="none" detail="false" (click)="logout()">
            <ion-label>Sair</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>

      <!-- <ion-list id="labels-list">
        <ion-list-header>Labels</ion-list-header>

        <ion-item *ngFor="let label of labels" lines="none">
          <ion-label>{{ label }}</ion-label>
        </ion-item>
      </ion-list> -->
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
  <tour-step-template></tour-step-template>
</ion-app>
