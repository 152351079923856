import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, effect, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { register } from 'swiper/element/bundle';
import { TourIonPopoverModule, TourService } from 'ngx-ui-tour-ionic';

import { ApiService } from './services/api.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    RouterLink,
    RouterLinkActive,
    CommonModule,
    TourIonPopoverModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppComponent implements OnInit {
  logged: any = "false";
  user: any;
  public quickAccess: any;


  constructor(
    private apiSrvc: ApiService,
    private tourService: TourService
  ) {
    effect(() => {
      this.user = this.apiSrvc.user();
      this.logged = this.apiSrvc.logged();
    });
  }

  ngOnInit(): void {
    this.apiSrvc.initializeCsrfProtection().then(() => {
      this.apiSrvc.getLinks().subscribe((res) => {
        this.quickAccess = res.data.links;
      });
      this.apiSrvc.checkAuthStatus();
    })

    this.tourService.initialize([{
      anchorId: 'tour1',
      content: 'Aqui você assiste um vídeo demonstrativo',
      title: 'Vídeo',
      enableBackdrop: true,
      prevBtnTitle: 'Anterior',
      nextBtnTitle: 'Seguinte',
      endBtnTitle: 'Fim',
    }, {
      anchorId: 'tour2',
      content: 'Este é o texto explicando a ajuda',
      title: 'Texto',
      enableBackdrop: true,
      prevBtnTitle: 'Anterior',
      nextBtnTitle: 'Seguinte',
      endBtnTitle: 'Fim',
    }]);
  }

  async login(){
    this.apiSrvc.login();
  }

  logout(){
    this.apiSrvc.logout();
  }

  navigate(url:string){
    window.open(url);
  }
  
}
