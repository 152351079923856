import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { leaveGuard } from './guards/leave.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full',
  },
  {
    path: 'inicio',
    loadComponent: () => import('./home/home.page').then( m => m.HomePage)
  },
  {
    path: 'categoria/:slug',
    loadComponent: () => import('./category/category.page').then( m => m.CategoryPage)
  },
  {
    path: 'canal/:name',
    loadComponent: () => import('./channel/channel.page').then( m => m.ChannelPage)
  },
  {
    path: 'favoritos',
    loadComponent: () => import('./favorites/favorites.page').then( m => m.FavoritesPage)
  },
  {
    path: 'item/:slug',
    loadComponent: () => import('./item/item.page').then( m => m.ItemPage)
  },
  {
    path: 'meu-conteudo',
    loadComponent: () => import('./my-content/my-content.page').then( m => m.MyContentPage)
  },
  {
    path: 'melhor-avaliados',
    loadComponent: () => import('./evaluated/evaluated.page').then( m => m.EvaluatedPage)
  },
  {
    path: 'publicados-recentemente',
    loadComponent: () => import('./published/published.page').then( m => m.PublishedPage)
  },
  {
    path: 'atualizados-recentemente',
    loadComponent: () => import('./updated/updated.page').then( m => m.UpdatedPage)
  },
  {
    path: 'ajuda',
    loadComponent: () => import('./wiki/wiki.page').then( m => m.WikiPage)
  },
  {
    path: 'admin',
    loadComponent: () => import('./admin/admin.page').then( m => m.AdminPage),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/item',
    loadComponent: () => import('./admin/item/item.page').then( m => m.ItemPage),
    canDeactivate: [leaveGuard]
  },
  {
    path: 'admin/item/:slug',
    loadComponent: () => import('./admin/item/item.page').then( m => m.ItemPage)
  },
  {
    path: '**',
    redirectTo: 'inicio',
    pathMatch: 'full',
  },
];
